import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { EventSourceInitDict } from "eventsource";
import {
  mockedHistoricalEvents,
  mockedIdentityData,
  mockedOrgResponse,
  mockedProfileTemplates,
  mockedProfileView,
  mockedProfileViewLong,
} from "./api.mocked";
import {
  CceCredentials,
  IdentityData,
  IdentityResponse,
  jsonPatchOperation,
  ProfileAttributeItem,
  ProfileViewTemplate,
} from "./types/cjaas";
import { debugLogMessage, errorConsoleLog } from "./utils";
import { CustomerEvent } from "./widgets";

export interface ProfileViewDataResponse {
  workspaceId?: string;
  organizationId?: string;
  personId?: string;
  templateId?: string;
  searchFilter?: null;
  attributes: Array<ProfileAttributeItem>;
  systemMetdata?: null;
  timestamp?: string;
}

/**
 * Get Historical Events
 * @param cceCredentials
 * @returns Promise<CustomerEvents[] | undefined>
 */
export const getCceBearerToken = (cceCredentials: CceCredentials): Promise<string> => {
  if (cceCredentials.tokenUrlName) {
    const url = `${cceCredentials.tokenUrl}?name=${cceCredentials.tokenUrlName}`;

    const config: AxiosRequestConfig = {
      method: "GET",
      url,
      headers: {
        "x-token-passphrase": cceCredentials.passKey,
      },
    };

    if (cceCredentials.userId) {
      config.headers["x-token-userid"] = cceCredentials.userId;
    }

    debugLogMessage(
      "calling getCceBearerToken function",
      "cceCredentials",
      cceCredentials,
      "url",
      url,
      "config",
      config
    );

    return axios(url, config)
      .then(response => response?.data?.token)
      .catch((error: AxiosError) => errorConsoleLog("Failed to fetch CCE token", error));
  } else {
    return Promise.reject("No cceTokenUrlName was provided");
  }
};

export class JourneyApiService {
  baseUrl: string;
  bearerToken?: string;
  mockData: boolean;
  mockApiDelay: number;

  constructor(baseUrl: string, bearerToken?: string, mockData: boolean = false) {
    debugLogMessage("JourneyApiService constructor", mockData, baseUrl, bearerToken);
    this.baseUrl = baseUrl;
    this.bearerToken = bearerToken;
    this.mockData = mockData;
    this.mockApiDelay = 300;
  }

  /**
   * Check if the organization is a JDS organization
   * @returns boolean
   */
  async isJDSOrg(): Promise<Boolean> {
    const url = `${this.baseUrl}/admin/v1/api/organization`;

    const config: AxiosRequestConfig = {
      method: "GET",
      url,
      headers: {
        Authorization: `Bearer ${this.bearerToken}`,
      },
    };

    if (this.mockData) {
      return new Promise(resolve => setTimeout(() => resolve(true), this.mockApiDelay));
    } else {
      return axios
        .create(config)
        .get(url)
        .then(() => true)
        .catch(() => false);
    }
  }

  /**
   * Get the project that has the WXCC connector enabled
   * @returns string
   */
  async getProjectIds() {
    const url = `${this.baseUrl}/admin/v1/api/workspace`;

    const config: AxiosRequestConfig = {
      method: "GET",
      url,
      headers: {
        Authorization: `Bearer ${this.bearerToken}`,
      },
    };

    if (this.mockData) {
      const returnValue = [{ id: "66a17c596830d075b53c69e0", wxccSubscriptionIds: ["111", "222", "333", "444"] }];
      return new Promise(resolve => setTimeout(() => resolve(returnValue), this.mockApiDelay));
    } else {
      return axios
        .create(config)
        .get(url)
        .then(response => {
          const workspaces = response?.data?.data;
          return workspaces;
        });
    }
  }

  /**
   * Get the project that has the WXCC connector enabled
   * @param projectId
   * @returns Array<ProfileTemplates>
   */
  async getProjectsProfileTemplates(projectId: string): Promise<Array<ProfileViewTemplate> | undefined> {
    const url = `${this.baseUrl}/admin/v1/api/profile-view-template/workspace-id/${projectId}`;

    const config: AxiosRequestConfig = {
      method: "GET",
      url,
      headers: {
        Authorization: `Bearer ${this.bearerToken}`,
      },
    };

    if (this.mockData) {
      return new Promise(resolve => setTimeout(() => resolve(mockedProfileTemplates?.data), this.mockApiDelay));
    } else {
      return axios
        .create(config)
        .get(url)
        .then(response => response?.data?.data);
    }
  }

  /**
   * Search for an Identity of an individual via aliases. This will return one/more Identities.
   * The Provided aliases belong to one/more Persons.
   * @param projectId
   * @param customer
   * @returns Promise<IdentityData | undefined>
   */
  async getIdentityDataByAlias(projectId: string, customer: string): Promise<IdentityData | undefined> {
    const url = `${this.baseUrl}/admin/v1/api/person/workspace-id/${projectId}/aliases/search`;
    const identitiesData = [customer];
    const config: AxiosRequestConfig = {
      method: "POST",
      url,
      headers: {
        Authorization: `Bearer ${this.bearerToken}`,
      },
      data: {
        identities: identitiesData,
      },
    };
    const payload = {
      identities: identitiesData,
    };

    if (this.mockData) {
      return new Promise(resolve => setTimeout(() => resolve(mockedIdentityData.data[0]), this.mockApiDelay));
    } else {
      return axios
        .create(config)
        .post(url, payload)
        .then((response: AxiosResponse<IdentityResponse>) => response?.data?.data?.[0]);
    }
  }

  /**
   * Add one or more aliases (or first / last name) to existing Individual
   * @param projectId
   * @param identityId
   * @param requestBody
   * @returns void
   */
  async patchAliasChange(projectId: string, identityId: string, requestBody: Array<jsonPatchOperation>) {
    const url = `${this.baseUrl}/admin/v1/api/person/workspace-id/${projectId}/person-id/${identityId}`;

    const config: AxiosRequestConfig = {
      method: "PATCH",
      data: JSON.stringify(requestBody),
      headers: {
        Authorization: `Bearer ${this.bearerToken}`,
        "Content-Type": "application/json-patch+json",
      },
    };

    return axios(url, config).then(response => response?.data?.data);
  }

  /**
   * Get ProfileView Data
   * @param requestUrl
   * @param templateReference
   * @returns Promise<ProfileDataPoint[] | undefined>
   */
  async getProgressiveProfileViewByTemplateId(
    projectId: string,
    customer: string,
    templateId: string
  ): Promise<ProfileViewDataResponse> {
    const url = `${this.baseUrl}/v1/api/progressive-profile-view/workspace-id/${projectId}/template-id/${templateId}/identity`;

    const config: AxiosRequestConfig = {
      method: "POST",
      url,
      headers: {
        Authorization: `Bearer ${this.bearerToken}`,
      },
      data: {
        identity: customer,
      },
    };
    const payload = {
      identity: customer,
    };

    if (this.mockData) {
      return new Promise(resolve => setTimeout(() => resolve(mockedProfileView.data[0]), this.mockApiDelay));
    } else {
      return axios
        .create(config)
        .post(url, payload)
        .then(response => response?.data?.data?.[0]);
    }
  }

  /**
   * Get Historical Events
   * @param projectId
   * @param encodedCustomer
   * @returns Promise<CustomerEvents[] | undefined>
   */
  getHistoricEvents(projectId: string, customer: string): Promise<CustomerEvent[] | undefined> {
    const url = `${this.baseUrl}/v1/api/events/workspace-id/${projectId}/identity`;

    const config: AxiosRequestConfig = {
      method: "POST",
      url,
      headers: {
        Authorization: `Bearer ${this.bearerToken}`,
      },
      data: {
        identity: customer,
      },
    };

    if (this.mockData) {
      // @ts-ignore
      return new Promise(resolve => setTimeout(() => resolve(mockedHistoricalEvents.data), this.mockApiDelay));
    } else {
      return axios(url, config).then(response => response?.data?.data);
    }
  }

  /**
   * get Timeline Stream API Url for subscription
   * @param projectId
   * @param customer
   * @returns string | undefined
   */
  getTimelineStreamUrl(projectId: string, customer: string): string {
    return `${this.baseUrl}/v1/api/events/stream/workspace-id/${projectId}/identity`;
  }
}
