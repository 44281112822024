import { Desktop } from "@wxcc-desktop/sdk";
import { debugLogMessage, errorConsoleLog } from "./utils";

export const mixpanelKey = "825aae060d0c93fac23d723eb7f71407";

export const enum JourneyWidgetMetricType {
  CJDS_WIDGET_LOAD = "[CJDS] Widget Initialized",
  IS_DEFAULT_TEMPLATE = "[CJDS] Default Template",
  PROGRESSIVE_PROFILE_LOADED = "[CJDS] Progressive Profile Loaded",
  PROGRESSIVE_PROFILE_FAILED = "[CJDS] Progressive Profile Failed",
  HISTORICAL_EVENTS_LOADED = "[CJDS] Historical Events Loaded",
  HISTORICAL_EVENTS_FAILED = "[CJDS] Historical Events Failed",
  EVENT_STREAM_FAILED = "[CJDS] Events stream Failed",
}

export const initAgentDesktopSDK = async (): Promise<void> => {
  debugLogMessage("Initializing Desktop SDK");

  Desktop.config
    .init({ widgetName: "cjdsWidget", widgetProvider: "Cisco" })
    .then(() => {
      debugLogMessage("Desktop SDK initialized");
    })
    .catch(error => {
      errorConsoleLog("Error initializing Desktop SDK", error);
    });
};

export function sendJourneyWidgetMetric(
  metricType: JourneyWidgetMetricType,
  options?: Record<string, string | number | boolean>
): void {
  Desktop.webexMetricsInternal.trackBehavioralEvent(metricType, options);
}
